import React from 'react';
import { Navigate, useRoutes } from "react-router-dom";

/* Pages Import */
import HomePage from "./pages/HomePage/HomePage"
import NotFoundPage from './pages/NotFoundPage/NotFoundPage';
import TermsNConditionsPage from './pages/TermsNConditionsPage/TermsNConditionsPage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage/PrivacyPolicyPage';
import FaqPage from './pages/FaqPage/FaqPage';
import ContactPage from './pages/ContactPage/ContactPage';
import CodeOfConductPage from './pages/CodeOfConductPage/CodeOfConductPage';
import CookiesPolicyPage from './pages/CookiesPolicyPage/CookiesPolicyPage';
import BlogsPage from './pages/BlogsPage/BlogsPage';
import PerBlogPage from './pages/BlogsPage/PerBlogPage/PerBlogPage';
import PerCategoryPage from './pages/BlogsPage/PerCategoryPage/PerCategoryPage';
import ServerErrorPage from './pages/ServerErrorPage/ServerErrorPage';
import DeletePage from './pages/DeletePage/DeletePage';

export default function Router() {
    const routes = useRoutes([
        {
            path: "/",
            element: <HomePage />,
        },
        {
            path: "/contact-us",
            element: <ContactPage />,
        },
        {
            path: "/delete-account",
            element: <DeletePage />,
        },
        {
            path: "/faq",
            element: <FaqPage />,
        },
        {
            path: "/privacy-policy",
            element: <PrivacyPolicyPage />,
        },
        {
            path: "/terms-and-conditions",
            element: <TermsNConditionsPage />,
        },
        {
            path: "/code-of-conduct",
            element: <CodeOfConductPage />,
        },
        {
            path: "/blogs",
            element: <BlogsPage />,
        },
        {
            path: "/blogs/:slug",
            element: <PerBlogPage />,
        },
        {
            path: "/blogs/category/:slug",
            element: <PerCategoryPage />,
        },
        {
            path: "/cookies-policy",
            element: <CookiesPolicyPage />,
        },
        {
            path: "/404",
            element: <NotFoundPage />,
        },
        {
            path: "/500",
            element: <ServerErrorPage />,
        },
        {
            path: "*",
            element: <Navigate to='/404' replace />,
        },
    ]);

    return routes;
}
