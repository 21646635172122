import React from 'react'
import DeleteAccountSection from '../../sections/DeleteAccount/DeleteAccountSection/DeleteAccountSection'

const DeletePage = () => {
  return (
    <div className='w-full bg-contactUs'>
      <DeleteAccountSection />
    </div>
  )
}

export default DeletePage
