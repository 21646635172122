import React from "react";
import { useState } from "react";
import Button from "../../../components/Button/Button";
import StyledH1Heading from "../../../common/components/styledH1Heading/StyledH1Heading";
import StyledH3Heading from "../../../common/components/styledH3Heading/StyledH3Heading";

const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

export default function DeleteAccountSection() {
    const [formData, setFormData] = useState({
        email: "",
        password: "",
        message: "",
        loading: false,
        show: false,
        alertmessage: "",
    });

    const [errors, setErrors] = useState({
        email: "",
        password: "",
        message: "",
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });

        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));

        if (name === "email") {
            setErrors((prevErrors) => ({
                ...prevErrors,
                email: emailRegex.test(value) ? "" : "Invalid email",
            }));
        } else if (name === "password") {
            setErrors((prevErrors) => ({
                ...prevErrors,
                [name]: value.length >= 5 ? "" : `Minimum characters 5 for ${name}`,
            }));
        } else if (name === "message") {
            setErrors((prevErrors) => ({
                ...prevErrors,
                [name]: value.length >= 5 ? "" : `Minimum characters 5 for ${name}`,
            }));
        }
    };

    return (
        <div className="flex flex-col w-[100%] justify-center items-center py-6 lg:py-10">
            <div className="flex flex-row w-full md:w-[80%] justify-center items-start gap-10">
                {/* heading section  */}
                <div className="flex flex-col w-full md:w-[70%] justify-between gap-5 md:gap-8 items-start">
                    <div className="flex flex-col w-[100%] justify-start items-center md:items-start md:py-4">
                        <StyledH1Heading fontColor="text-yellow md:text-black">Delete Account</StyledH1Heading>
                    </div>
                    {/* Form Section */}
                    <div className=" flex w-[100%] md:w-[90%] justify-center shadow-xl bg-yellow md:bg-white md:rounded-[18px]">
                        <div className="flex flex-col w-[90%] md:w-[80%] gap-6 py-6">
                            <div className="flex flex-col items-center gap-2 md:items-start">
                                <StyledH3Heading fontColor="text-black">Fill in the form</StyledH3Heading>
                            </div>

                            <div className="flex flex-col xl:flex-row justify-between gap-4 w-[100%]">
                                <div className="flex flex-col w-full gap-1">
                                    <input
                                        placeholder="Email"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                        className="flex p-3 border rounded-md shadow-md focus:outline-none border-light-gray"
                                        maxLength={20}
                                    />
                                    {errors.email && (
                                        <p className="text-sm text-red-500">{errors.email}</p>
                                    )}
                                </div>
                                <div className="flex flex-col w-full gap-1">
                                    <input
                                        placeholder="Password"
                                        name="password"
                                        value={formData.password}
                                        onChange={handleChange}
                                        className="flex p-3 border rounded-md shadow-md focus:outline-none border-light-gray"
                                        maxLength={20}
                                    />
                                    {errors.password && (
                                        <p className="text-sm text-red-500">{errors.password}</p>
                                    )}
                                </div>
                            </div>
                            <div className="flex flex-col justify-between gap-4">
                                <textarea
                                    rows="10"
                                    placeholder="Provide reason..."
                                    name="message"
                                    value={formData.message}
                                    onChange={handleChange}
                                    className="flex p-3 border rounded-md shadow-md focus:outline-none border-light-gray"
                                    maxLength={500}
                                />
                                {errors.message && (
                                    <p className="text-sm text-red-500">{errors.message}</p>
                                )}
                            </div>
                            <div className="flex self-center">
                                <Button
                                    text={`${formData.loading ? " Deleting..." : "Delete Account"}`}
                                    bgColor="bg-white md:bg-yellow"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex-col md:w-[30%] justify-between hidden gap-6 md:flex">
                    <img
                        src="assets/images/contactUs.png"
                        alt="Contact Us"
                        className="flex self-start "
                    />
                </div>
            </div>
            <div className={formData.loading ? "loading-bar" : ""}></div>
        </div>
    );
}
