import React, { useEffect, useRef, useState } from 'react'
import { callApi } from '../../../API/callApi'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Loader from '../../../components/loader/Loader'
import { Icon } from '@iconify/react/dist/iconify.js'
import StyledSmText from '../../../common/components/styledSmText/StyledSmText'
import StyledH4Heading from '../../../common/components/styledH4Heading/StyledH4Heading'
import StyledH1Heading from '../../../common/components/styledH1Heading/StyledH1Heading'
import BlogsCard from '../../../components/blogsCard/BlogsCard'
import Skeleton from '../../../components/skeleton/Skeleton'
import CardSkeleton from '../../../components/cardSkeleton/CardSkeleton'
import BlogSkeleton from '../../../components/blogSkeleton/BlogSkeleton'
import { Helmet } from 'react-helmet'

const PerBlogPageSection = () => {

    const [singleBlogData, setSingleBlogData] = useState([])
    const [blogCategoryData, setBlogCategoryData] = useState([])
    const [loading, setLoading] = useState(true)
    const [loadingImage, setLoadingImage] = useState(true);
    const params = useParams()
    const navigate = useNavigate()
    const imageRef = useRef()

    const getSingleBlogData = async () => {
        try {
            const data = {
                slug: params.slug
            }
            const response = await callApi("blogs/get-single-blog", "POST", data)
            setSingleBlogData(response.data.data)
            setLoading(false)
        } catch (error) {
            console.log("🚀 ~ getSingleBlogData ~ error:", error)
            if (error?.response?.data?.status === 404) {
                navigate("/404", { replace: true })
            }
            else if (error.message === "Network Error") {
                navigate("/500");
            }
            else {
                setLoading(false)
                return error;
            }
            setLoading(false)
        }
    }

    const getBlogCategoryData = async () => {
        try {
            if (singleBlogData.categoryId?.name) {
                const data = { slug: singleBlogData?.categoryId?.name?.toLowerCase() }
                const response = await callApi("blogs/get-blogs-by-category", "POST", data)
                setBlogCategoryData(response.data.data.blogs)
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        if (params?.slug !== "") {
            getSingleBlogData()
        }
        if (singleBlogData.categoryId?.name) {
            getBlogCategoryData()
        }

        const timer = setTimeout(() => {
            setLoadingImage(false);
        }, 1000);

        return () => clearTimeout(timer);
    }, [loading])

    // Share links
    const blogLink = process.env.REACT_APP_SERVER_URL + "/blogs/" + params.slug;
    const titleAndLink = singleBlogData.title + "\n" + blogLink;

    // sharer links for social media platforms
    const facebook = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(blogLink)}`;
    const twitter = `https://twitter.com/intent/tweet?text=${encodeURIComponent(blogLink)}`;
    const linkedin = `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(blogLink)}}`
    const whatsapp = "https://wa.me/?text=" + titleAndLink;

    // Filter out the current blog and select two blogs
    const filteredBlogs = blogCategoryData.filter(item => item.slug !== params.slug).slice(0, 2);

    return (
        <div className='w-[90%] lg:w-[80%] xl:w-[60%]'>
            <Helmet>
                {singleBlogData?.title && <title>FITS | {singleBlogData?.title}</title>}
                {singleBlogData?.description && <meta name="description" content={singleBlogData?.description} />}
                {singleBlogData?.metaKeywords && <meta name="keywords" content={singleBlogData?.metaKeywords} />}
            </Helmet>
            {
                loading ? <BlogSkeleton /> :
                    <div className='flex flex-col w-full py-10'>
                        <div className='flex flex-col w-full gap-4'>
                            <div className='relative flex flex-col-reverse w-full gap-4'>
                                <div className='flex flex-row lg:flex-col lg:absolute lg:top-[25%] lg:left-[2.5%] items-end justify-center lg:w-[5%] gap-3'>
                                    <Link to={facebook} target='__blank' className='text-[#808184] hover:text-yellow hover:border-yellow bg-[#f5f5f5] hover:bg-yellow hover:bg-opacity-10 border border-[#808184] p-2 rounded-[10px]'>
                                        <Icon icon="ri:facebook-fill" width="25" height="25" />
                                    </Link>
                                    <Link to={twitter} target='__blank' className='text-[#808184] hover:text-yellow hover:border-yellow bg-[#f5f5f5] hover:bg-yellow hover:bg-opacity-10 border border-[#808184] p-2 rounded-[10px]'>
                                        <Icon icon="hugeicons:new-twitter" width="25" height="25" />
                                    </Link>
                                    <Link to={linkedin} target='__blank' className='text-[#808184] hover:text-yellow hover:border-yellow bg-[#f5f5f5] hover:bg-yellow hover:bg-opacity-10 border border-[#808184] p-2 rounded-[10px]'>
                                        <Icon icon="formkit:linkedin" width="25" height="25" />
                                    </Link>
                                    <Link to={whatsapp} target='__blank' className='text-[#808184] hover:text-yellow hover:border-yellow bg-[#f5f5f5] hover:bg-yellow hover:bg-opacity-10 border border-[#808184] p-2 rounded-[10px]'>
                                        <Icon icon="mdi:whatsapp" width="25" height="25" />
                                    </Link>
                                </div>
                                <div className='flex justify-center w-full'>
                                    {loadingImage ? (
                                        <div className='rounded-[18px] h-[300px] md:h-[400px] w-[672px]'>
                                            <Skeleton />
                                        </div>
                                    ) : (
                                        <img ref={imageRef}
                                            className='rounded-[18px] h-[300px] md:h-[400px] w-[672px] object-cover'
                                            src={`${process.env.REACT_APP_SERVER_URL}/uploads/blogImages/${singleBlogData.image}`}
                                            alt='bg'
                                        />
                                    )}
                                </div>
                            </div>
                            <div className='w-full lg:w-[78%] self-center flex flex-col gap-3'>
                                <div className='flex items-center w-full gap-2'>
                                    <div className='text-[#969696]'>
                                        <Icon icon="uiw:date" width="15" height="15" />
                                    </div>
                                    <div>
                                        <StyledSmText fontColor='text-light-gray'>{new Date(singleBlogData.createdAt).toDateString().substring(4)}</StyledSmText>
                                    </div>
                                </div>
                                <div className='flex items-center justify-between w-full'>
                                    <div>
                                        <Link className='text-sm text-light-gray font-inter hover:underline' to={`/blogs/category/${singleBlogData.categoryId.slug}`}>{singleBlogData.categoryId.name}</Link>
                                    </div>
                                    <div>
                                        <StyledSmText fontColor='text-light-gray'>{singleBlogData.authorId.name}</StyledSmText>
                                    </div>
                                </div>
                                <div className='w-full'>
                                    <StyledH1Heading fontColor="text-black">{singleBlogData.title}</StyledH1Heading>
                                </div>
                                <div className='flex flex-col gap-4 tinymce' dangerouslySetInnerHTML={{ __html: singleBlogData.body }} />
                                {
                                    filteredBlogs.length > 0 &&
                                    <div className='flex flex-col w-full gap-4 pt-20'>
                                        <div>
                                            <StyledH4Heading fontColor="text-black">Similar Blogs</StyledH4Heading>
                                        </div>
                                        <div className='grid grid-cols-1 gap-3 md:grid-cols-2'>
                                            {
                                                filteredBlogs.map((item, index) => (
                                                    <BlogsCard data={item} key={index} />
                                                ))
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
            }
        </div>
    )
}

export default PerBlogPageSection
