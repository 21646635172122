import React, { useEffect, useState } from 'react';
import StyledSmText from '../../common/components/styledSmText/StyledSmText';
import StyledH5Heading from '../../common/components/styledH5Heading/StyledH5Heading';
import { Link } from 'react-router-dom';
import Skeleton from '../skeleton/Skeleton';

const BlogsCard = ({ data, index }) => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const loadImage = () => {
            const img = new Image();
            img.onload = () => {
                setLoading(false); // Set loading to false once the image is loaded
            };
            img.onerror = () => {
                setLoading(false); // Set loading to false if there's an error loading the image
            };
            img.src = `${process.env.REACT_APP_SERVER_URL}/uploads/blogImages/thumbnails/${data.image}`;
        };

        if (data.image) {
            loadImage();
        } else {
            setLoading(false);
        }
    }, [data.image]);

    return (
        <div key={index} className='w-full flex flex-col h-full gap-3 grid-cols-1 bg-white rounded-[18px] border border-[#e0e0e0] p-3 lg:p-4 cardDropShadow'>
            <Link to={`/blogs/${data.slug}`} className='w-full rounded-[18px]'>
                {loading ? (
                    <div className='rounded-[18px] h-[200px]'>
                        <Skeleton />
                    </div>
                ) : (
                    <img
                        src={`${process.env.REACT_APP_SERVER_URL}/uploads/blogImages/thumbnails/${data.image}`}
                        className='rounded-[18px] h-[200px] w-full object-cover object-center min-h-[200px]'
                        alt={`Card`}
                    />
                )}
            </Link>
            <div className='flex flex-col gap-1'>
                <div>
                    <StyledSmText fontColor="text-dark-gray">Category : <Link className='font-bold underline text-yellow' to={`/blogs/category/${data.categoryId.slug}`}>{data.categoryId.name}</Link></StyledSmText>
                </div>
                <Link to={`/blogs/${data.slug}`}>
                    <StyledH5Heading fontColor="text-dull-black !font-extrabold">{data.title}</StyledH5Heading>
                </Link>
            </div>
            <div>
                <StyledSmText fontColor="text-dark-gray">{data.authorId.name} | {new Date(data.createdAt).toDateString().substring(4)}</StyledSmText>
            </div>
        </div>
    );
};

export default BlogsCard;
